import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Fundo from "../../assets/fundo-login.jpg";
import api from "../../services/api.js";
import SaltPassword from "../../services/md5.js";
import './style.css';
import LoadingSpinner from "../../components/loading-spinner"; // Supondo que o spinner esteja aqui

function Cadastro() {
    const navigate = useNavigate();
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [senha2, setSenha2] = useState("");
    const [estabelecimento, setEstabelecimento] = useState("");
    const [endereco, setEndereco] = useState("");
    const [complemento, setComplemento] = useState("");
    const [bairro, setBairro] = useState("");
    const [cidade, setCidade] = useState("");
    const [uf, setUF] = useState("");
    const [codCidade, setCodCidade] = useState("");
    const [cep, setCep] = useState("");
    const [codCategoria, setCodCategoria] = useState(0);
    const [mensagem, setMensagem] = useState('');
    const [cidades, setCidades] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(false); // Estado de loading

    function ListarCategorias(cod_cid) {
        api.get('/v1/categorias?cod_cidade=' + cod_cid)
            .then(response => {
                setCategorias(response.data);
            })
            .catch(err => {
                console.log(err);
            })
           
    }

    function HandleCidade(e) {
        const [cid, est] = e.target[e.target.selectedIndex].text.split(" - "); // SAO PAULO - SP
        setCidade(cid);
        setUF(est);
        setCodCidade(e.target.value);
        ListarCategorias(e.target.value);
    }

    function ProcessaConta() {
        setMensagem("");
        if (senha !== senha2) {
            setMensagem('As senhas não conferem. Digite novamente.');
            return;
        }

        setLoading(true); // Iniciar o loading
        api.post('admin/usuarios/registro', {
            nome,
            email,
            senha: senha.length > 0 ? SaltPassword(senha) : '',
            estabelecimento,
            endereco,
            complemento,
            bairro,
            cidade,
            uf,
            cep,
            cod_cidade: codCidade,
            id_categoria: codCategoria
        }).then(retorno => {
            if (retorno.status === 201) {
                localStorage.setItem('sessionToken', retorno.data.token);
                localStorage.setItem('sessionId', retorno.data.id_usuario);
                localStorage.setItem('sessionEmail', email);
                localStorage.setItem('sessionCodCidade', codCidade);
                localStorage.setItem('sessionCidade', cidade);
                localStorage.setItem('sessionUF', uf);
                navigate('/');
            } else {
                if (retorno.response) {
                    setMensagem('Ocorreu um erro no cadastro: ' + retorno.response.data.erro);
                } else {
                    setMensagem('Ocorreu um erro no cadastro');
                }
            }
        }).catch(err => {
            if (err.response) {
                setMensagem(err.response.data.erro);
            } else {
                setMensagem("Ocorreu um erro ao acessar o servidor");
            }
        }).finally(() => setLoading(false)); // Finalizar o loading
    }

    useEffect(() => {
        
        api.get('/v1/cidades')
            .then(response => {
                setCidades(response.data);
            })
            .catch(err => {
                console.log(err);
            })
            
    }, []);

    return (
        <div className="row">
            <div className="col-sm-6 d-flex justify-content-center align-items-center text-center">
                <form className="form-cadastro mt-5">
                    <h3 className="mb-4">Crie sua conta agora mesmo.</h3>

                    {loading ? <LoadingSpinner /> : (
                        <>
                            {/* Formulário aqui */}
                            <div className="row">
                            <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setNome(e.target.value)} className="form-control" id="floatingInput" placeholder="Nome completo" />
                            <label htmlFor="floatingInput">Nome do contato</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="floatingInput" placeholder="E-mail" />
                            <label htmlFor="floatingInput">E-mail</label>
                        </div>
                    </div>
                </div> 

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="password" onChange={(e) => setSenha(e.target.value)} className="form-control" id="floatingInput" placeholder="Senha" />
                            <label htmlFor="floatingInput">Senha</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="password" onChange={(e) => setSenha2(e.target.value)} className="form-control" id="floatingInput" placeholder="Confirme a senha" />
                            <label htmlFor="floatingInput">Confirme a senha</label>
                        </div>
                    </div>
                </div>  

                <div className="form-floating mt-4">
                    <input type="text" onChange={(e) => setEstabelecimento(e.target.value)} className="form-control" id="floatingInput" placeholder="Nome completo" />
                    <label htmlFor="floatingInput">Nome do estabelecimento</label>
                </div>

                <div className="row">
                    <div className="col-lg-8">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setEndereco(e.target.value)} className="form-control" id="floatingInput" placeholder="Endereço" />
                            <label htmlFor="floatingInput">Endereço</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-4">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setComplemento(e.target.value)} className="form-control" id="floatingInput" placeholder="Compl." />
                            <label htmlFor="floatingInput">Compl.</label>
                        </div>
                    </div>
                </div>    

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setBairro(e.target.value)} className="form-control" id="floatingInput" placeholder="Bairro" />
                            <label htmlFor="floatingInput">Bairro</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-control mb-2">
                            <select name="cidades" id="cidades" onChange={HandleCidade}>
                                <option value="0000000">Cidade</option>
                                
                                {
                                    cidades.map((cid) => {
                                        return <option key={cid.cod_cidade} value={cid.cod_cidade}>{cid.cidade} - {cid.uf}</option>
                                    })
                                }

                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                            <input type="text" onChange={(e) => setCep(e.target.value)} className="form-control mb-2" id="floatingInput" placeholder="CEP" />
                            <label htmlFor="floatingInput">CEP</label>
                        </div>
                    </div>
                    
                    <div className="col-lg-6">
                        <div className="form-control mb-2">
                            <select name="categoria" id="categoria" onChange={(e) => setCodCategoria(e.target.value)} >
                                <option value="0000000">Categoria</option>
                                
                                {categorias.map(c => {
                                    return <option key={c.id_categoria} value={c.id_categoria}>{c.categoria}</option>
                                })}

                            </select>
                        </div>
                    </div>
                            </div>
                            <button className="w-100 btn btn-lg btn-danger" onClick={ProcessaConta} type="button">
                                <span className="ms-2">Criar Conta</span>
                            </button>
                            {mensagem.length > 0 && <div className="alert alert-danger mt-2" role="alert">{mensagem}</div>}
                        </>
                    )}

                    <div className="mt-5">
                        <Link to="/login">Já tenho uma conta. Fazer Agora!</Link>
                    </div>
                    <img src={Logo} alt="Delivery Plus" className="mt-5" />
                </form>
            </div>

            <div className="col-sm-6 px-0 d-none d-sm-block">
                <img className="background-login" src={Fundo} alt="Delivery Mais" />
            </div>
        </div>
    );
}

export default Cadastro;
