import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/index.jsx";
import Pedido from "../../components/pedido/index.jsx";
import api from "../../services/api.js";
import LoadingSpinner from "../../components/loading-spinner"; 
import somAlerta from '../../assets/som.wav'; 
import './style.css';

function Pedidos() {
    const [pedidos, setPedidos] = useState([]);
    const [loading, setLoading] = useState(false); 

    // Função para tocar som de alerta
    function tocarSomAlerta() {
        const audio = new Audio(somAlerta);
        audio.play();
    }

    // Função para enviar notificação
    function enviarNotificacao(novoPedido) {
        if (Notification.permission === "granted") {
            new Notification("Novo Pedido Recebido", {
                body: `Pedido nº ${novoPedido.id_pedido} de ${novoPedido.nome}.`,
                icon: "/icone-pedido.png" 
            });
        }
    }

    

    // Função para listar pedidos
    function ListarPedidos() {
        setLoading(true); 

        api.get('/admin/pedidos?status_not_in=F')
            .then(response => {
                const novosPedidos = response.data || []; 

                // Verifica pedidos com status "Aguardando"
                const pedidosAguardando = novosPedidos.filter(pedido => pedido.status === "A");

                if (pedidosAguardando.length > 0) {
                    pedidosAguardando.forEach(pedido => {
                        tocarSomAlerta(); 
                        enviarNotificacao(pedido);
                    });
                }

                setPedidos(novosPedidos); // Atualiza os pedidos
            })
            .catch(err => {
                console.error('Erro ao listar pedidos:', err);
                setPedidos([]); 
            })
            .finally(() => {
                setLoading(false); 
            });
    }

    useEffect(() => {
        if (Notification.permission !== "granted") {
            Notification.requestPermission().then(permission => {
                if (permission !== "granted") {
                    console.log("Permissão para notificações negada.");
                }
            });
        }

        ListarPedidos();

        const interval = setInterval(() => {
            ListarPedidos();
        }, 60000); 

        return () => clearInterval(interval);
    }, []); 

    return (
        <>
            <Navbar tela="pedidos" />

            <div className="container-fluid mt-page">
                <div className="m-2 mt-4 mb-4 d-flex justify-content-between">
                    <h2>Acompanhamento de Pedidos</h2>
                    <button className="btn btn-outline-danger ms-4" onClick={ListarPedidos}>
                        <i className="fas fa-sync"></i> Atualizar
                    </button>
                </div>

                <div className="row ms-0 me-1">
                    {
                        loading ? (
                            <div className="loading-screen">
                                <LoadingSpinner /> 
                                <p>Carregando pedidos...</p>
                            </div>
                        ) : pedidos.length > 0 ? (
                            pedidos.map(pedido => (
                                <div key={pedido.id_pedido} className="pedido-item">
                                    <Pedido
                                        id_pedido={pedido.id_pedido}
                                        dt_pedido={pedido.dt_pedido}
                                        vl_total={pedido.vl_total}
                                        vl_taxa_entrega={pedido.vl_taxa_entrega}
                                        status={pedido.status}
                                        nome={pedido.nome}
                                        endereco={pedido.endereco}
                                        complemento={pedido.complemento}
                                        fone={pedido.fone}
                                        bairro={pedido.bairro}
                                        itens={pedido.itens}
                                        onClickStatus={ListarPedidos}
                                    />
                                </div>
                            ))
                        ) : (
                            <p>Nenhum pedido encontrado.</p>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default Pedidos;
