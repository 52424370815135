import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo2.png";
import "./style.css";

function Navbar(props){

    const navigate = useNavigate();

    function Logout(){
        localStorage.removeItem("sessionToken");
        localStorage.removeItem("sessionId");
        localStorage.removeItem("sessionEmail");
        localStorage.removeItem("sessionCodCidade");
        localStorage.removeItem("sessionCidade");
        localStorage.removeItem("sessionUF");

        navigate('/login');
    }

    let classDefault = "btn btn-outline-light me-3"
    let classSelecionado = "btn btn-light me-3" 

    return <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-danger ps-3 pe-3">
    <div className="container-fluid">
        <a className="navbar-brand" href="/"><img className="mt-1" src={Logo} alt="Delivery Mais" /></a>
        
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            
                            
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <Link className={props.tela === "/" ? classSelecionado : classDefault} aria-current="page" to="/"><i className="fas fa-home"></i>Início</Link>
                </li>                
                <li className="nav-item mt-1">
                <Link className={props.tela === "pedidos" ? classSelecionado : classDefault} to="/pedidos"><i className="fas fa-utensils"></i>Acompanhar Pedidos</Link>
                </li>
                <li className="nav-item mt-1">
                <Link className={props.tela === "cardapio" ? classSelecionado : classDefault} to="/cardapio"><i className="fas fa-bars"></i>Cardápio</Link>
                </li>
                <li className="nav-item mt-1">
                <Link className={props.tela === "historico" ? classSelecionado : classDefault} to="/historico"><i className="fas fa-clock"></i>Histórico Pedidos</Link>
                </li>
            </ul>

            <div className="btn-group ">
                <button type="button" className="btn btn-outline-light me-3 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-user"></i>{localStorage.getItem('sessionNome')}
                </button>
                <ul className="dropdown-menu dropdown-menu-end">                    
                    <li><Link to="/perfil" className="dropdown-item">Meu Perfil</Link></li>
                    <li><Link to="/senha" className="dropdown-item">Alterar Senha</Link></li>
                    <li><Link to="/estabelecimento" className="dropdown-item">Estabelecimento</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><button className="dropdown-item" onClick={Logout} type="button">Sair</button></li>
                </ul>
            </div>

            

        </div>
    </div>
</nav>
}

export default Navbar;