import { useEffect, useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import closeIcone from "../../assets/close.png";
import api from "../../services/api.js";
 
function CategoriaModal(props){

    const [id_categoria, setId_categoria] = useState(0);
    const [categoria, setCategoria] = useState("");
    const [ind_ativo, setInd_Ativo] = useState(false);
    const [mensagem, setMensagem] = useState("");

    function SalvarCategoria(){

        setMensagem("");

        if (id_categoria > 0){
            api.put(`/admin/produtos/categorias/${id_categoria}`, {
                categoria,
                ind_ativo: ind_ativo ? "S" : "N"
            })
            .then(retorno => {  
                if (retorno.response){
                    if (retorno.response.status != "200"){
                        setMensagem(retorno.response.data.erro);
                    }
                } else {
                    props.onRequestClose();
                    props.onClickSave();                                
                }
            })
            .catch(err => {            
                if (err.response) {
                    setMensagem(err.response.data.erro);
                } else {
                    setMensagem('Ocorreu um erro na requisição.');
                }
            });
        } else {
            api.post('/admin/produtos/categorias', {
                categoria,
                ind_ativo: ind_ativo ? "S" : "N"
              })
              .then(retorno => {    
                  if (retorno.response){
                      if (retorno.response.status != "201"){
                        setMensagem(retorno.response.data.erro);
                      }
                  } else {        
                      props.onRequestClose();
                      props.onClickSave();
                  }
              })
              .catch(err => {            
                  if (err.response) {
                      setMensagem(err.response.data.erro);
                  } else {
                      setMensagem('Ocorreu um erro na requisição.');
                  }
              });
        }

    }

    useEffect(() => {
        setId_categoria(props.dados_categoria.id_categoria);
        setCategoria(props.dados_categoria.categoria);
        setInd_Ativo(props.dados_categoria.ind_ativo == "S" ? true : false);
    }, [props.isOpen]);

    return <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">

                <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                    <img src={closeIcone} alt="Fechar" />
                </button>

                <h4>Categoria</h4>

                <div className="row">
                    <div className="col-12 mt-4 mb-4">
                    <div className="form-floating">
                        <input type="text" onChange={(e) => setCategoria(e.target.value)} value={categoria} className="form-control" id="floatingInput" placeholder="Categoria" />
                        <label htmlFor="floatingInput">Categoria</label>
                    </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-4 mt-2">
                    <div className="form-check">
                        <input className="form-check-input" onChange={(e) => setInd_Ativo(e.target.checked)} checked={ind_ativo} type="checkbox" id="defaultCheck1" />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                            Ativo
                        </label>
                        </div>
                    </div>
                </div>

                {mensagem.length > 0 ? <div className="alert alert-danger mt-2 text-center" role="alert">{mensagem}</div> : null}

                <div className="row">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div>                                                  
                            <button className="btn btn-danger ms-4" onClick={SalvarCategoria}>Salvar</button>
                            <button className="btn btn-outline-danger ms-2" onClick={props.onRequestClose}>Cancelar</button>
                        </div>
                    </div>
                </div>

        </Modal>
}
 
export default CategoriaModal;