import { useEffect, useState } from "react";
import api from "../../services/api.js";
import Navbar from "../../components/navbar/index.jsx";
import Pedido from "../../components/pedido/index.jsx";
import LoadingSpinner from "../../components/loading-spinner/index.jsx";  // Import do spinner
import './style.css';

function Historico() {
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(false);  // Estado para controlar o spinner

  const ListarPedidos = async () => {
    setLoading(true);  // Inicia o loading
    try {
      const response = await api.get('/admin/pedidos?status=F');
      setPedidos(response.data);
    } catch (err) {
      console.error("Erro ao listar pedidos: ", err);
    } finally {
      setLoading(false);  // Para o loading após a requisição
    }
  };

  useEffect(() => {
    ListarPedidos();
  }, []);

  return (
    <>
      <Navbar tela="historico" />

      <div className="container-fluid mt-page">
        <div className="m-2 mt-4 mb-4 d-flex justify-content-between">
          <h2>Histórico de Pedidos</h2>
          <button className="btn btn-outline-danger ms-4" onClick={ListarPedidos}>
            <i className="fas fa-sync"></i> Atualizar
          </button>
        </div>

        <div className="row ms-0 me-1">
          {loading ? (  // Exibe o spinner enquanto carrega
            <LoadingSpinner />
          ) : (
            pedidos.length > 0 ? (
              pedidos.map((pedido) => (
                <Pedido
                  key={pedido.id_pedido}
                  dt_pedido={pedido.dt_pedido}
                  bairro={pedido.bairro}
                  complemento={pedido.complemento}
                  endereco={pedido.endereco}
                  id_pedido={pedido.id_pedido}
                  itens={pedido.itens}
                  nome={pedido.nome}
                  status={pedido.status}
                  vl_total={pedido.vl_total}
                  onClickStatus={ListarPedidos}
                />
              ))
            ) : (
              <p className="text-center">Nenhum pedido encontrado.</p>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default Historico;
