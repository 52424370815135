import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import closeIcone from "../../assets/close.png";
import "./style.css";
import api from "../../services/api.js";
import {NumericFormat} from "react-number-format";
import {v4 as uuidv4} from "uuid";


function ProdutoOpcionalModal(props){

    const [jsonDados, setJsonDados] = useState([]);
    const [id_produto, setId_Produto] = useState(0);

    function AddGrupo(){
        const grupo = {
            id_opcao: uuidv4(),
            descricao: "",
            ind_obrigatorio: "S",
            qtd_max_escolha: 1,
            ind_ativo: "S",
            itens: []
        }

        setJsonDados([...jsonDados, grupo]);
    }

    function DeleteGrupo(id_opcao){
        let json = [];

        jsonDados.map((op) => {
            if (op.id_opcao != id_opcao){
                json.push(op);
            }
        });

        setJsonDados(json);
    }

    function AddOpcional(id_opcao){
        let json = [];
        let item = {
            id_item: uuidv4(),
            nome: "",
            vl_item: 0
        }

        jsonDados.map((op) => {
            if (op.id_opcao == id_opcao){
                op.itens.push(item);
            }

            json.push(op);
        });

        setJsonDados(json);

    }

    function DeleteOpcional(id_opcao, id_item){
        let json = [];
        let itens = [];

        jsonDados.map((op) => {
            if (op.id_opcao == id_opcao){

                op.itens.map((item) => {
                    if (item.id_item != id_item){
                        itens.push(item);
                    }                    
                });

                op.itens = itens; 
            }

            json.push(op);
        });

        setJsonDados(json);
    }

    function HandleDescricaoChange(descricao, index){
        const json = [...jsonDados];

        json[index].descricao = descricao;

        setJsonDados(json);
    }

    function HandleQtdChange(qtd, index){
        const json = [...jsonDados];

        json[index].qtd_max_escolha = qtd;

        setJsonDados(json);
    }

    function HandleAtivoChange(checked, index){
        
        const json = [...jsonDados];
        
        json[index].ind_ativo = checked ? "S" : "N";
        
        setJsonDados(json);
    }

    function HandleObrigatorioChange(checked, index){
        
        const json = [...jsonDados];
        
        json[index].ind_obrigatorio = checked ? "S" : "N";
        
        setJsonDados(json);
    }

    function HandleOpcionalDescricaoChange(nome, indexOpcao, indexItem){
        
        const json = [...jsonDados];
        
        json[indexOpcao].itens[indexItem].nome = nome;
        
        setJsonDados(json);
    }

    function HandleOpcionalValorChange(valor, indexOpcao, indexItem){
        
        const json = [...jsonDados];
        
        json[indexOpcao].itens[indexItem].vl_item = valor;
        
        setJsonDados(json);
    }

    function ListarDados(id){

        if (id > 0) {
            api.get(`/admin/cardapios/opcionais/${id}`)
            .then(response => {
                setJsonDados(response.data);            
            })
            .catch(err => {
                console.log(err);
            });
        }
    }

    function SalvarDados(){
        api.post(`admin/cardapios/opcionais/${id_produto}`, jsonDados)
        .then(retorno => {  
            if (retorno.response){
                if (retorno.response.status != "200"){
                    alert(retorno.response.data.erro);
                }
            } else {          
                props.onRequestClose();                
            }
        })
        .catch(err => {            
            if (err.response) {
                alert(err.response.data.erro);
            } else {
                alert('Ocorreu um erro na requisição.');
            }
        });
    }

    useEffect(() => {
        
        setId_Produto(props.id_produto);

        if (props.isOpen) {
            ListarDados(props.id_produto);
        }        

    }, [props.isOpen]);

    return <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">

                <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                    <img src={closeIcone} alt="Fechar" />
                </button>
 
                
                <div className="m-2 mt-4 mb-4 d-flex justify-content-between">
                    <h4>Opcionais do Produto</h4>
                    <button className="btn btn-outline-danger ms-4" onClick={AddGrupo}><i className="fas fa-plus"></i>Adicionar Grupo</button>
                </div> 

                <div className="scroll">
                
                    <div className="row mt-4 mb-3">

                        <div className="col-12 mb-2">

                            {
                                jsonDados.map((op, indexOpcao) => {
                                    return <ul className="list-group mb-5" key={op.id_opcao}>
                                        <li className="list-group-item produto-opcional p-4">
                                        
                                            <div className="row">
                                                <div className="col-9">
                                                    <input type="text" onChange={(e) => HandleDescricaoChange(e.target.value, indexOpcao)} value={op.descricao} className="form-control" placeholder="Grupo dos opcionais" />
                                                </div>
                                                <div className="col-3 text-end">
                                                    <button className="btn btn-danger" onClick={() => DeleteGrupo(op.id_opcao)} ><i className="fas fa-trash"></i>Excluir</button>
                                                </div>
                                            </div>
                                            
                                            <div className="row mt-3">
                                                <div className="col-5">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="basic-addon3">Qtd. Max</span>
                                                        </div>
                                                        
                                                        <NumericFormat  className="form-control input-small" 
                                                                        value={op.qtd_max_escolha} 
                                                                        allowLeadingZeros 
                                                                        decimalSeparator="," 
                                                                        thousandSeparator="." 
                                                                        allowedDecimalSeparators={false}
                                                                        allowNegative={false}
                                                                        onValueChange={(values, sourceInfo) => {                                                                
                                                                            HandleQtdChange(values.value, indexOpcao)
                                                                        }} />

                                                    </div>
                                                </div>

                                                <div className="col-3 mt-2">
                                                    <div className="form-check">
                                                        <input className="form-check-input" onChange={(e) => HandleAtivoChange(e.target.checked, indexOpcao)} type="checkbox" defaultChecked={op.ind_ativo == "S" ? true : false} id="defaultCheck1" />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Ativo
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-3 mt-2">
                                                    <div className="form-check">
                                                        <input className="form-check-input" onChange={(e) => HandleObrigatorioChange(e.target.checked, indexOpcao)}  type="checkbox" defaultChecked={op.ind_obrigatorio == "S" ? true : false} id="defaultCheck1" />
                                                        <label className="form-check-label" htmlFor="defaultCheck1">
                                                            Obrigatório
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                                                                                            
                                        </li> 

                                        {
                                            op.itens.map((item, indexItem) => {
                                                return <li className="list-group-item pt-3 border-bottom-0" key={item.id_item}>
                                                <div className="row">
                                                    <div className="col-1 pe-0">
                                                        
                                                    </div>
                                                    <div className="col-8 pe-0">
                                                        <input type="text" onChange={(e) => HandleOpcionalDescricaoChange(e.target.value, indexOpcao, indexItem)} value={item.nome} className="form-control" placeholder="Nome do opcional" />
                                                    </div>
                                                    <div className="col-2 pe-0">
                                                    <NumericFormat  className="form-control" 
                                                                    defaultValue={parseFloat(item.vl_item)} 
                                                                    allowLeadingZeros={false} 
                                                                    decimalScale={2}
                                                                    fixedDecimalScale={true}
                                                                    inputMode="decimal"
                                                                    decimalSeparator=","
                                                                    thousandSeparator="."
                                                                    placeholder="Valor"
                                                                    onValueChange={(values, sourceInfo) => {
                                                                        HandleOpcionalValorChange(values.value, indexOpcao, indexItem)
                                                                    }} />
                                                    </div>
                                                    <div className="col-1">
                                                        <button className="btn" onClick={(e) => DeleteOpcional(op.id_opcao, item.id_item)} ><i className="fas fa-trash me-0"></i></button>
                                                    </div>
                                                </div>
                                            </li>        
                                            })
                                        }

                                        <li className="list-group-item p-3">
                                            <div className="row">                                    
                                                <div className="col-12">
                                                    <button className="btn btn-outline-danger" onClick={(e) => AddOpcional(op.id_opcao)}><i className="fas fa-plus"></i>Adicionar opcional</button>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                })
                            }

                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div>                                                  
                            <button className="btn btn-danger ms-4" onClick={SalvarDados}>Salvar</button>
                            <button className="btn btn-outline-danger ms-2" onClick={props.onRequestClose}>Cancelar</button>
                        </div>
                    </div>
                </div>

        </Modal>
}
 
export default ProdutoOpcionalModal;