import "./style.css";
import { StatusPedido } from "../../services/functions.js";
import api from "../../services/api.js";
import React from "react";

function Pedido(props) {
    // Função para alterar o status do pedido
    function AlterarStatus(st) {
        api.put('admin/pedidos/status/' + props.id_pedido, {
            status: st
        }).then(response => {
            props.onClickStatus();
        }).catch(err => {
            console.log(err);
        });
    }

   
    async function imprimirPedido() {
        try {
            // Dados do pedido
            const pedidoData = {
                empresa: localStorage.getItem('sessionNome'),
                id_pedido: props.id_pedido,
                nome: props.nome,
                dt_pedido: new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short', timeStyle: 'short', timeZone: 'UTC' }).format(new Date(props.dt_pedido)),
                endereco: props.endereco+' - '+props.bairro+' - '+
                          props.complemento+' - '+props.fone,                
                vl_total: props.vl_total,
                vl_taxa_entrega: props.vl_taxa_entrega,
                itens: props.itens.map(item => ({
                    descricao: item.descricao,
                    detalhes: item.detalhes ? item.detalhes.map(detalhe => ({
                        nome: detalhe.nome,
                        qtde: detalhe.qtde,
                        vl_unitario: detalhe.vl_unitario                        
                    })) : [],
                    qtd: item.qtd,
                    vl_unit: item.vl_unit,
                    vl_total: item.vl_total
                }))
            };
            
            
            // URL do servidor de impressão
            const serverUrl = 'http://localhost:2222/imprimir'; // Ajuste para a URL do seu servidor de impressão
    
            // Envia os dados para o servidor de impressão
            const response = await fetch(serverUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pedidoData), 
                mode: "no-cors"
                
            });
    
        } catch (error) {
            console.error('Erro ao enviar pedido para impressão:', error);
         }
    }
     
         
    
    const dt_ped = new Date(props.dt_pedido);
    const formattedDate = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(dt_ped);
    const formattedTime = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short', timeZone: 'UTC' }).format(dt_ped);

    return (
        <div className="d-flex justify-content-between shadow-lg pedido me-4" key={props.id_pedido}>
            <div>
                <span><b>Pedido #{props.id_pedido}</b></span>
                <span className="badge rounded-pill bg-secondary ms-3">
                    {formattedDate} - {formattedTime}
                </span>
                {props.status === 'A' ? (
                    <span className="badge rounded-pill bg-danger ms-3">{StatusPedido(props.status)}</span>
                ) : (
                    <span className="badge rounded-pill bg-success ms-3">{StatusPedido(props.status)}</span>
                )}
                <small className="d-block mt-1 mb-3 text-secondary">
                    {props.nome} - {props.endereco} - {props.bairro} <br/>{props.complemento} - {props.fone}                   
                </small>

                {Array.isArray(props.itens) && props.itens.map(item => (
                    <div className="col-12" key={item.id_item}>
                        <div className="row p-3 ps-0 border-top">
                            <div className="col-3">
                                <img className="img-fluid rounded img-produto" src={item.url_foto} alt="Produto" />
                            </div>
                            <div className="col-9">
                                <div className="d-flex justify-content-between align-items-center">
                                    <small><b>{item.descricao}</b></small>
                                    <small><b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vl_unit * item.qtd)}</b></small>
                                </div>
                                <small className="d-block mb-2">
                                    {item.qtd.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })} x 
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vl_unit)}
                                </small>
                                {item.detalhes && item.detalhes.map(detalhe => (
                                    <small className="d-block text-secondary" key={detalhe.nome}>- {detalhe.nome}</small>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="d-flex align-items-center me-4">
                {props.status !== "F" ? (
                    <div className="dropdown">
                        <a className="btn btn-secondary dropdown-toggle" 
                           role="button" 
                           id={`dropdownMenuLink${props.id_pedido}`} 
                           data-bs-toggle="dropdown" 
                           aria-expanded="false">
                            Status
                        </a>                
                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuLink${props.id_pedido}`}>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("A")}>Aguardando</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("P")}>Em produção</a></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("E")}>Saiu para entrega</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" onClick={() => AlterarStatus("F")}>Finalizar</a></li>
                        </ul>
                    </div>
                ) : (
                    <button className="btn btn-outline-danger ms-4" onClick={() => AlterarStatus("A")}>
                        <i className="fas fa-arrow-left"></i>Reabrir Pedido
                    </button>
                )}

       {/* Botão de imprimir */}
       {props.status === "A" && (
                    <button className="btn btn-primary ms-3" onClick={imprimirPedido}>
                        Imprimir Pedido
                    </button>
                )}
    
            </div>
        </div>
    );
}

export default Pedido;
