import { useState } from "react";
import Navbar from "../../components/navbar/index.jsx";
import SaltPassord from "../../services/md5.js";
import api from "../../services/api.js";
import LoadingSpinner from "../../components/loading-spinner"; // Supondo que o spinner esteja aqui

function Senha() {

    const [senha, setSenha] = useState("");
    const [senha2, setSenha2] = useState("");
    const [sucesso, setSucesso] = useState("");
    const [loading, setLoading] = useState(false); // Estado de loading

    function SalvarDados() {
        setSucesso("");

        if (senha !== senha2) {
            alert("As senhas não conferem. Digite novamente");
            return;
        }

        setLoading(true); // Iniciar o loading ao salvar dados

        api.put('admin/usuarios/senha', {
            senha: SaltPassord(senha),
            senha2: SaltPassord(senha2)
        }).then(retorno => {
            if (retorno.response) {
                if (retorno.response.status !== "200") {
                    alert(retorno.response.data.erro);
                }
            } else {
                setSucesso("S");
            }
        }).catch(err => {
            setSucesso('N');
        }).finally(() => setLoading(false)); // Finalizar o loading
    }

    return (
        <div className="container-fluid mt-page cardapio">
            <Navbar />

            <div className="row col-lg-6 offset-lg-3">
                <div className="col-12 mt-4">
                    <h2 className="mt-2">Alterar Senha</h2>
                </div>

                <div className="col-12 mt-4">
                    <div>
                        {loading ? (
                            <LoadingSpinner /> // Exibir o spinner durante o carregamento
                        ) : (
                            <>
                                <div className="mb-4">
                                    <label htmlFor="InputNome" className="form-label">Informe a nova senha</label>
                                    <input
                                        type="password"
                                        onChange={(e) => setSenha(e.target.value)}
                                        className="form-control"
                                        id="InputNome"
                                        aria-describedby="nome"
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="InputEmail" className="form-label">Confirme a nova senha</label>
                                    <input
                                        type="password"
                                        onChange={(e) => setSenha2(e.target.value)}
                                        className="form-control"
                                        id="InputEmail"
                                        aria-describedby="email"
                                    />
                                </div>

                                {sucesso === "S" && (
                                    <div className="alert alert-success mt-2" role="alert">
                                        Senha alterada com sucesso.
                                    </div>
                                )}

                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-danger mt-3" onClick={SalvarDados}>
                                        Alterar Senha
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Senha;
