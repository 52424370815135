import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home/index.jsx";
import Login from "./pages/login/index.jsx";
import Cadastro from "./pages/cadastro/index.jsx";
import Cardapio from "./pages/cardapio/index.jsx";
import Pedidos from "./pages/pedidos/index.jsx";
import Historico from "./pages/historico/index.jsx";
import Perfil from "./pages/perfil/index.jsx";
import Senha from "./pages/senha/index.jsx";
import Estabelecimento from "./pages/estabelecimento/index.jsx";
import PrivateRoute from "./components/private-route/index.jsx";

function Rotas(){
    return <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/registro" element={<Cadastro/>} />

            <Route path="/" element={<PrivateRoute><Home/></PrivateRoute>} />            
            <Route path="/cardapio" element={<PrivateRoute><Cardapio/></PrivateRoute>} />
            <Route path="/pedidos" element={<PrivateRoute><Pedidos/></PrivateRoute>} />
            <Route path="/historico" element={<PrivateRoute><Historico/></PrivateRoute>} />
            <Route path="/perfil" element={<PrivateRoute><Perfil/></PrivateRoute>} />
            <Route path="/senha" element={<PrivateRoute><Senha/></PrivateRoute>} />
            <Route path="/estabelecimento" element={<PrivateRoute><Estabelecimento/></PrivateRoute>} />
        </Routes>
    </BrowserRouter>
}

export default Rotas;